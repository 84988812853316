import React, { useEffect } from "react";
import { Route, Router, RouterProvider, Routes, useLocation, useNavigate } from "react-router-dom";

import { Footer } from "../components/core/Footer";
import { Header } from "../components/core/Header";
import AboutPage from "../pages/AboutPage";
import ContactPage from "../pages/ContactPage";
import OccupationalTherapyPage from "../pages/OccupationalTherapyPage";
import FAQsPage from "../pages/FAQsPage";
import NeurologyGeriatricsPage from "../pages/NeurologyGeriatricsPage";
import PediatricsPage from "../pages/PediatricsPage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import PsychiatryPage from "../pages/PsychiatryPage";
import TermsAndConditionsPage from "../pages/TermsAndConditionsPage";
import UnknownPage from "../pages/UnknownPage";
import {
  PATH_ABOUT,
  PATH_ADDITIONAL_OFFERS,
  PATH_CONTACT,
  PATH_ERGOTHERAPIE,
  PATH_FAQ as PATH_FAQS,
  PATH_NEUROLOGIE_GERIATRIE,
  PATH_PEDIATRICS,
  PATH_PRIVACY_POLICY,
  PATH_PSYCHIATRIE,
  PATH_START,
  PATH_TERMS_AND_CONDITIONS,
  PATH_UNKNOWN,
} from "../utils/constants";
import StartPage from "../pages/StartPage";
import OtherOffersPage from "../pages/OtherOffersPage";
import { PageBackground } from "../components/layout/PageBackground";

const ContentContainer = ({ children }: { children: React.ReactNode }) => (
  <div className="pt-0 pb-32 md:pt-0 md:pb-40 lg:pt-0 lg:pb-48 min-h-screen bg-white dark:bg-gray-900">
    <div>
      <main className="isolate">
        <div className="relative isolate -z-10 overflow-hidden">
          <div className="mx-auto px-4 sm:px-6 md:px-8 lg:px-12 xl:px-16 2xl:px-24 flex justify-center">
            <div className="w-full max-w-7xl min-w-0">{children}</div>
          </div>
        </div>
      </main>
    </div>
  </div>
);

export const PublicApp = () => {
  const location = useLocation();

  // Reset scroll position when route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Header />
      <ContentContainer>
        <Routes>
          <Route path={PATH_START} element={<StartPage />} />
          <Route path={PATH_ERGOTHERAPIE} element={<OccupationalTherapyPage />} />
          <Route path={PATH_NEUROLOGIE_GERIATRIE} element={<NeurologyGeriatricsPage />} />
          <Route path={PATH_PSYCHIATRIE} element={<PsychiatryPage />} />
          <Route path={PATH_PEDIATRICS} element={<PediatricsPage />} />
          <Route path={PATH_ADDITIONAL_OFFERS} element={<OtherOffersPage />} />
          <Route path={PATH_ABOUT} element={<AboutPage />} />
          <Route path={PATH_CONTACT} element={<ContactPage />} />
          <Route path={PATH_FAQS} element={<FAQsPage />} />
          <Route path={PATH_TERMS_AND_CONDITIONS} element={<TermsAndConditionsPage />} />
          <Route path={PATH_PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
          <Route path={PATH_UNKNOWN} element={<UnknownPage />} />
        </Routes>
      </ContentContainer>
      <Footer />
    </>
  );
};
