import React from "react";
import { SEOHelmet } from "../components/common/SEOHelmet";
import { TestimonialsComponent } from "../components/common/testimonial/TestimonialComponent";
import { BookOpenIcon, AcademicCapIcon, HandRaisedIcon, HeartIcon, UserGroupIcon } from "@heroicons/react/24/outline";

type OfferSection = {
  title: string;
  content: string[];
  details?: {
    label: string;
    value: string;
  }[];
  texts?: string[];
  isMainSection?: boolean;
  icon?: "book" | "academic" | "hand" | "heart" | "group";
};

const data: { sections: OfferSection[] } = {
  sections: [
    {
      title: "Weitere Angebote",
      content: [],
      texts: [
        "Neben der klassischen Ergotherapie bieten wir auch weitere Schulungen und Kurse an. Hier finden Sie eine Übersicht über die aktuellen Angebote.",
      ],
      isMainSection: true,
    },
    {
      title: "Legasthenietraining nach der AFS-Methode",
      content: [],
      texts: [
        "Das Training unterstützt Ihr Kind dabei, seine Lese- und Schreibfähigkeit zu verbessern. Dabei ist es besonders wichtig, das Kind ganzheitlich zu betrachten.",
        "Die neusten wissenschaftlichen Erkenntnisse von Dr. Astrid Kopp-Duller zeigen, dass Kinder in den Bereichen: Aufmerksamkeit, Sinneswahrnehmung, sowie Lese und Schreibfähigkeit zu fördern sind. Wir setzen an den Ressourcen ihres Kindes an, damit es positive Erfahrungen sammeln kann und verbinden diese mit seinen Problembereichen.",
      ],
      isMainSection: true,
      icon: "book",
    },
    {
      title: "Was könnte eine Behandlung beinhalten?",
      content: [
        "Erstgespräch mit den Eltern",
        "Diagnostik und Erstellung eines individuellen Förderplans",
        "Einzeltraining und Übungsblätter für ein gezieltes Training zu Hause",
      ],
    },
    {
      title: "THOP Elterntraining",
      content: [
        "Das eigene Kind besser verstehen lernen, wir nehmen die Probleme unter die Lupe, als Eltern/Familie gemeinsame Wege finden, die Stärken des Kindes sehen, gut geplant ist halb entspannt, Austausch untereinander.",
      ],
      details: [
        {
          label: "Wer",
          value: "Eltern von Kindern im Grundschulalter (6-12 Jahre), bei Diagnose oder Verdacht auf ADHS",
        },
        {
          label: "Kosten",
          value: "Mögliche Kostenübernahme durch das Programm STÄRKE, acht Module á 120 Minuten",
        },
        {
          label: "Angeboten von",
          value: "Charlotte Fröhlich",
        },
      ],
      isMainSection: true,
      icon: "group",
    },
    {
      title: "Energiemanagement Schulung",
      content: [
        'Individuelles Erstgespräch, fünf Gruppenlektionen zu Themen wie Pausenmanagement und wirkungsvolle Kommunikation, individuelles Einzelgespräch zum Thema "meine Ziele im Alltag", begleitendes Selbsttraining für zu Hause',
      ],
      details: [
        {
          label: "Wer",
          value:
            "Klienten:Innen mit Fatigue, z.B. nach Post-Covid Erkrankung, Krebserkrankung, Schlaganfall, Schädel-Hirn-Trauma, Morbus Parkinson, Multiple Sklerose, Narkolepsie",
        },
        {
          label: "Kosten",
          value: "Mögliche Kostenübernahme durch die Krankenkasse",
        },
        {
          label: "Angeboten von",
          value: "Sandra Meyer",
        },
      ],
      isMainSection: true,
      icon: "academic",
    },
    {
      title: "Selbstwert-Kurs für Kinder",
      content: [
        "Stärkung des Selbstbewusstseins durch Wahrnehmung und Respektierung des eigenen Körpers und der eigenen Grenzen, Übungen zum selbstbewussten Auftreten, Prävention gefährlicher Situationen, Selbstverteidigungstechniken",
      ],
      details: [
        {
          label: "Wer",
          value: "Kinder im Alter von 5-8 Jahren",
        },
        {
          label: "Kosten",
          value: "80€ für acht Einheiten",
        },
        {
          label: "Angeboten von",
          value: "Charlotte Fröhlich",
        },
      ],
      isMainSection: true,
      icon: "heart",
    },
    {
      title: "Handtherapie",
      content: [
        "Rheumatische Erkrankungen (z.B. Arthritis)",
        "Verletzungen der Sehnen, Bänder, Gelenkkapseln und Knochen",
        "Karpaltunnelsyndrom, M. Duypuytren",
        "Morbus Sudeck",
        "Vor- und Nachbehandlung von Operationen",
        "Postoperativen Narben",
      ],
      texts: [
        "Eine Handtherapie kommt dann zum Tragen, wenn die Funktionen der Hand durch eine Schädigung in ihrer Funktionalität beeinträchtigt ist. Durch kleinste Verletzungen der Hand, kann es bereits zu gravierenden Einschränkungen im Alltag und oder Berufsleben kommen. Je nach Art und Verletzung werden unterschiedliche ergotherapeutische Maßnahmen ergriffen, um die Funktionalität der Hand wieder herzustellen und/oder zu verbessern.",
      ],
      isMainSection: true,
      icon: "hand",
    },
    {
      title: "Was beinhaltet eine motorisch- funktionelle Behandlung?",
      content: [
        "Narbenbehandlung (professionelle Nachsorge durch entsprechende Pflege und Massage der Narbe)",
        "Verbesserung der Feinmotorik und der manuellen Geschicklichkeit",
        "Schmerzreduktion",
        "Wiederaufnahme von Alltagstätigkeiten oder das Erlernen von Kompensationsstrategien",
        "Erlernen von Gelenkschutzmaßnahmen zur Reduzierung von schmerzbedingten Reaktionen",
        "Verbesserung der Sensibilität",
        "Verbesserung der Beweglichkeit, der Muskelkraft, der Koordination, der Ausdauer und der Belastbarkeit",
        "Thermische Anwendungen (Wärme/Kälte) z.B. Vorbereitung und Unterstützung von aktiver und/oder passiver Mobilisation",
        "Taping = funktionelle Verbandstechnik, Gelenke können dadurch stabilisiert werden und vor weiteren Traumatisierungen geschützt werden.",
      ],
    },
    {
      title: "Was noch berücksichtigt werden sollte?",
      content: [
        "Psychische Stabilisierung durch begleitende Gespräche (z.B. zur Krankheitsverarbeitung)",
        "Nach Möglichkeit: Übungen für ein Eigentraining zu Hause",
        "Eventuell Einbeziehung von Angehörigen",
        "ggf. Arbeitsplatzgestaltung zur Schmerzreduzierung",
      ],
    },
  ],
};

const testimonials = [
  {
    quote:
      "Mein Morbus Sudeck hat mein Leben ganz schön auf den Kopf gestellt. Dank der Ergotherapie kann ich meine Hand wieder im Alltag einsetzen. Auch die Gespräche über meine Ängste und Sorgen haben mir sehr gut getan.",
    section: "Handtherapie",
  },
];

const IconComponent: React.FC<{ icon?: OfferSection["icon"] }> = ({ icon }) => {
  switch (icon) {
    case "book":
      return <BookOpenIcon className="h-6 w-6" />;
    case "academic":
      return <AcademicCapIcon className="h-6 w-6" />;
    case "hand":
      return <HandRaisedIcon className="h-6 w-6" />;
    case "heart":
      return <HeartIcon className="h-6 w-6" />;
    case "group":
      return <UserGroupIcon className="h-6 w-6" />;
    default:
      return null;
  }
};

export default function OtherOffersPage() {
  return (
    <>
      <SEOHelmet page="otherOffers" />

      <main className="space-y-8 sm:space-y-16">
        {/* Main sections */}
        {data.sections.map((section, index) => (
          <article key={index}>
            {section.isMainSection ? (
              section.title === "Weitere Angebote" ? (
                // Intro section without card styling
                <section aria-labelledby={`section-${index}`} className="relative">
                  <div className="relative">
                    <h1
                      id={`section-${index}`}
                      className="text-3xl sm:text-4xl md:text-5xl font-semibold tracking-tight text-gray-900 dark:text-white mb-6 sm:mb-8 drop-shadow-sm"
                    >
                      {section.title}
                    </h1>
                    <div className="space-y-3">
                      {section.texts?.map((text, index) => (
                        <p
                          key={index}
                          className="text-base sm:text-lg text-gray-600 dark:text-gray-300 leading-relaxed"
                        >
                          {text}
                        </p>
                      ))}
                    </div>
                  </div>
                </section>
              ) : (
                // Main sections with full content and card styling
                <section aria-labelledby={`section-${index}`} className="relative">
                  <div className="relative">
                    {/* Header with Icon */}
                    <div className="flex items-start sm:items-center gap-4 sm:gap-6 mb-6 sm:mb-8">
                      {section.icon && (
                        <div
                          className="p-3 sm:p-4 rounded-xl bg-ergo-100/80 dark:bg-ergo-900/80 
                          shadow-[0_8px_20px_rgb(139,211,230,0.2)] dark:shadow-[0_8px_20px_rgb(139,211,230,0.1)]
                          transform group-hover:scale-105 transition-all duration-300 flex-shrink-0"
                        >
                          <div className="text-ergo-600 dark:text-ergo-400">
                            <IconComponent icon={section.icon} />
                          </div>
                        </div>
                      )}
                      <h2
                        id={`section-${index}`}
                        className="text-2xl sm:text-3xl md:text-4xl font-semibold tracking-tight text-gray-900 dark:text-white drop-shadow-sm"
                      >
                        {section.title}
                      </h2>
                    </div>

                    {/* Content Card */}
                    <div
                      className="relative backdrop-blur-sm bg-white/80 dark:bg-gray-900/80 rounded-xl sm:rounded-2xl 
                      shadow-[0_8px_30px_rgb(0,0,0,0.04)] dark:shadow-[0_8px_30px_rgb(0,0,0,0.2)]
                      border border-ergo-100/60 dark:border-gray-700/60 p-6 sm:p-8 md:p-10
                      hover:shadow-[0_8px_30px_rgb(0,0,0,0.08)] dark:hover:shadow-[0_8px_30px_rgb(0,0,0,0.3)] 
                      transition-all duration-300"
                    >
                      <div className="space-y-6 sm:space-y-8">
                        {section.content.length > 0 && (
                          <div className="space-y-3">
                            {section.content.map((item, index) => (
                              <p
                                key={index}
                                className="text-base sm:text-lg text-gray-600 dark:text-gray-300 leading-relaxed"
                              >
                                {item}
                              </p>
                            ))}
                          </div>
                        )}

                        {section.texts?.map((text, index) => (
                          <p
                            key={index}
                            className="text-base sm:text-lg text-gray-600 dark:text-gray-300 leading-relaxed"
                          >
                            {text}
                          </p>
                        ))}

                        {section.details && (
                          <dl className="grid gap-4 sm:gap-6">
                            {section.details.map((detail, index) => (
                              <div key={index} className="flex flex-col sm:flex-row sm:gap-4">
                                <dt className="text-base sm:text-lg font-medium text-gray-900 dark:text-white sm:w-32 flex-shrink-0">
                                  {detail.label}
                                </dt>
                                <dd className="text-base sm:text-lg text-gray-600 dark:text-gray-300 leading-relaxed">
                                  {detail.value}
                                </dd>
                              </div>
                            ))}
                          </dl>
                        )}
                      </div>
                    </div>

                    {/* Testimonial for specific section */}
                    {testimonials.map(
                      (testimonial, tIndex) =>
                        testimonial.section === section.title && (
                          <div key={tIndex} className="mt-8">
                            <TestimonialsComponent testimonials={[{ quote: testimonial.quote }]} />
                          </div>
                        )
                    )}
                  </div>
                </section>
              )
            ) : (
              // Supporting sections with simpler styling
              <section aria-labelledby={`section-${index}`} className="relative">
                <div className="relative">
                  <h2
                    id={`section-${index}`}
                    className="text-xl sm:text-2xl font-semibold tracking-tight text-ergo-600 dark:text-ergo-400 mb-6 drop-shadow-sm"
                  >
                    {section.title}
                  </h2>
                  <div className="space-y-3">
                    {section.content.map((item, index) => (
                      <p key={index} className="text-base sm:text-lg text-gray-600 dark:text-gray-300 leading-relaxed">
                        {item}
                      </p>
                    ))}
                  </div>
                </div>
              </section>
            )}
          </article>
        ))}
      </main>
    </>
  );
}
