import { FaInstagram } from "react-icons/fa";
import { PrimaryButton } from "./PrimaryButton";

export const InstagramSection = () => {
  return (
    <div className="relative">
      <div className="px-6 pt-8 sm:px-8 lg:px-12">
        <div className="relative mx-auto">
          {/* Card Container */}
          <div
            className="relative backdrop-blur-sm bg-white/70 dark:bg-gray-900/70 rounded-2xl shadow-[0_8px_30px_rgb(0,0,0,0.04)] 
            border border-ergo-100/60 dark:border-gray-700 p-12"
          >
            {/* Header Section */}
            <div className="text-center space-y-8">
              {/* Instagram Icon */}
              <div className="flex justify-center">
                <div
                  className="p-4 rounded-xl bg-ergo-100/80 dark:bg-ergo-900/80 
                  shadow-[0_8px_20px_rgb(139,211,230,0.2)] transform hover:scale-105 transition-all duration-300"
                >
                  <FaInstagram className="h-7 w-7 text-ergo-600 dark:text-ergo-400" />
                </div>
              </div>

              {/* Text Content */}
              <div className="space-y-4">
                <h2 className="text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
                  Folgen Sie uns auf Instagram
                </h2>
                <p className="text-base text-gray-600 dark:text-gray-300 max-w-xl mx-auto font-medium">
                  Bleiben Sie auf dem Laufenden über unsere Praxis und erhalten Sie wertvolle Tipps.
                </p>
              </div>

              {/* Follow Button */}
              <div className="pt-4">
                <PrimaryButton
                  href="https://www.instagram.com/ergotherapie_rottweil"
                  className="shadow-[0_8px_20px_rgb(139,211,230,0.2)] hover:shadow-[0_12px_30px_rgb(139,211,230,0.3)]
                    dark:shadow-[0_8px_20px_rgb(139,211,230,0.1)] dark:hover:shadow-[0_12px_30px_rgb(139,211,230,0.2)]"
                >
                  <div className="flex items-center gap-x-3">
                    <FaInstagram className="h-5 w-5" />
                    <span className="font-semibold tracking-wide">Auf Instagram folgen</span>
                  </div>
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
